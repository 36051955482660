.Landing__head {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  margin-left: 0.8em;
}

.Landing nav {
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Landing__topcourses {
  padding: 2em 0em;
  display: flex;
  justify-content: space-evenly;
}

section {
  border: white !important;
}

.Landing__rightnav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40vw;
  z-index: 5;
}

.Landing {
  margin: 0 auto;
}

.Landing__navlink {
  margin-right: 1em;
  color: white;
}

/* Hero section styles */
.Landing__herosection {
  background-color: var(--color-primary);
  height: 80vh;
  text-align: center;
  color: white;
  position: relative;
}

.Landing__herocontent {
  padding: 100px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Landing__herotitle {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  top: -10em;
  text-align: left;
  margin-left: 16em;
  width: 50vw;
}

.Landing__herosearch {
  width: 40em;
  padding: 1em;
  border-radius: 5px;
  font-size: 0.3em;
  border: none;
  background-color: rgba(255, 255, 255, 0.7);
}

.Landing__herobutton {
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-top: 11.5px;
  padding-bottom: 11.5px;
  height: 3em;
}

.Landing__herobutton:hover {
  background-color: #f7f7f7;
}

.Landing__sectiontitle {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.Landing__categorieslist {
  display: flex;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 5em;
}

.call-to-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.call-to-action img {
  width: 50vw;
  margin-left: 10em;
}

.Landing hr {
  width: 99.9% !important;
}

.Landing__calltoaction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20em;
}

.Landing__calltoaction > button {
  background-color: var(--color-primary);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  cursor: pointer;
  margin-top: 1em;
  width: fit-content;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.Landing__check {
  margin-left: 1em;
  margin-top: 10px;
}

.Landing__checklist {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 5em;
}

.Landing__checklist ul {
  line-height: 4;
  font-size: 1.3em;
  margin-left: 2em;
}

/* Course Categories section styles */
.Landing__categories {
  padding: 1em;
  border-top: 1px solid;
}

.Landing__divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.Landing__divider svg {
  position: relative;
  display: block;
  width: calc(154% + 1.3px);
  height: 160px;
}

.Landing__divider .Landing__shapefill {
  fill: white;
}
