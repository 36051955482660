.CourseView {
  display: flex;
}

.CourseView__left {
  margin-left: 3vw;
}

.CourseView__header > h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 58.09px;
  color: rgba(255, 255, 255, 1);
}

.CourseView__header {
  background-image: linear-gradient(
    90deg,
    rgba(148, 34, 255, 0.856),
    rgba(255, 123, 62, 0.644)
  );
  color: white;
  padding: 3em;
  margin-top: 2em;
  border-radius: 2em;
  width: 55vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
}

.CourseView__header > button {
  font-weight: 800;
}

.CourseView__desc {
  margin-top: 1vh;
}

.CourseView__taughtBy {
  display: flex;
  align-items: center;
}

.CourseView___taughtBy > span {
  font-weight: 800;
}

.CourseView__enrollButton {
  width: 10em;
  padding: 1em;
  border: none;
  box-shadow: 2px 4px 4px rgba(255, 39, 39, 0.25);
  border-radius: 5px;
  background-color: var(--color-secondary);
  color: rgba(255, 255, 255, 1);
  margin-top: 10vh;
  margin-bottom: 3vh;
}

.CourseView__tabs {
  margin-top: 10vh;
  width: 55vw;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.CourseView__tabs > span {
  font-weight: 1000;
  cursor: pointer;
}

.CourseView hr {
  width: 50vw;
}

.CourseView__right {
  padding: 2em;
  border-radius: 0.5em;
  margin-left: 3vw;
  margin-top: 2em;
  border: 1px solid black;
  height: fit-content;
  width: 25vw;
  line-height: 2em;
}

.CourseView__price {
  font-size: 52px;
  font-weight: 1000;
  margin-bottom: 5vh;
}

.CourseView__details {
  margin-top: 1em;
  line-height: 2.5em;
  margin-bottom: 10vh;
}

.CourseView__review {
  border: 1px solid black;
  border-radius: 1em;
  padding: 2em;
  width: 50%;
  margin-bottom: 2em;
}

.CourseView__submitReview {
  border-radius: 0.5em;
  background: var(--color-primary);
  color: white;
  border: none !important;
  font-weight: 800;
  width: fit-content !important;
  height: fit-content;
  margin-top: 1em;
  padding: 1em;
}

.CourseView__reviewForm {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 1em;
  padding: 2em;
  width: 50%;
  align-items: center;
}

.CourseView__reviewForm > input {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid black;
  width: 80%;
}

.CourseView__reviewForm > textarea {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid black;
  width: 80%;
  height: 10vh;
}

.CourseView__popupButton {
  border: none;
  background: none;
}

.CourseView__reviewButtons {
  display: flex;
  position: absolute;
  left: 32vw;
}

.CourseView__stars {
  display: flex;
}

.CourseView__stars > span {
  margin-right: 1em;
}

.Search__star {
  font-size: 2em;
  color: #d3d3d3;
}

.Search__starSelected {
  color: #ffcc00;
  font-size: 2em;
}
