.EditReview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EditReview__button {
  background-color: var(--color-primary);
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 0.5em 1em;
  margin: 20px 20px;
  border: none;
  font-weight: 800;
}

.EditUser__button:hover {
  cursor: pointer;
}

.EditUser__input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5em 1em;
  margin: 0.5em 0;
}

.EditUser__input textarea {
  height: 10em;
}

#file {
  margin-left: 5vw;
}

.EditUser__avatar {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  margin-bottom: 3em;
  margin-top: 2em;
}

.EditReview > label {
  font-weight: bold;
}
