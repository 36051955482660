.UserShort {
  display: flex;
  align-items: center;
  margin-right: 1em;
  margin-bottom: 0.5em;
}

.UserShort__avatar {
  width: 3em;
  height: 3em;
  border-radius: 50%;
}
