.Pagination {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-bottom: 3em;
}

.Pagination > input {
  width: 5em;
  padding: 0.5em;
}

.Pagination__button {
  background-color: var(--color-primary);
  color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  padding: 0.5em;
  border: none;
  font-weight: 800;
}
