.Card {
  width: 20vw;
  border: 1px solid lightgray;
  padding: 2em;
  border-radius: 0.5em;
  text-align: center;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.Card__img {
  width: 20em;
  height: 20em;
  object-fit: cover;
}

.Card > p {
  font-size: 0.7em;
  margin-top: 1em;
}
