.Search__head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Search input {
  padding: 1em;
  border-radius: 5px;
}

.Search button {
  padding: 1em;
  border-radius: 5px;
  margin-left: 1em;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
}

.Search input[type="submit"] {
  padding: 1em;
  border-radius: 5px;
  margin-left: 1em;
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
}

.Search {
  display: flex;
  flex-direction: column;
  width: 99vw;
}

.Search > h1 {
  text-align: center;
  padding: 1em;
}

.Search__box {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* This creates two columns of equal width */
  gap: 5em; /* This adds a gap between the grid items */
}

.Search__superbox {
  display: flex;
  margin-bottom: 10vh;
  justify-content: center;
}

.Search__paginationContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10vh;
}

.Search__hr {
  width: 100%;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.Search__filters {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.Search__filter {
  display: flex;
  align-items: center;
}

.Search__filter > span {
  margin-right: 1em;
}

.Search__radio {
  display: none;
}

.Search__star {
  font-size: 2em;
  color: #d3d3d3;
}

.Search__starSelected {
  color: #ffcc00;
  font-size: 2em;
}

.Search__input {
  width: 40vw;
  margin-left: 2em;
}
