* {
  margin: 0;
}

.User__leftside {
  flex: 1;
  margin-top: 3.5em;
  border-radius: 10px;
  border: 1px solid lightgray;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 3em;
  margin-bottom: 2em;
  height: fit-content;
}

.User__img {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
}

.User__info {
  display: flex;
}

.User__info > p {
  margin-top: 2px;
}

.User__rightside {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30vh;
}

.User__iconcont {
  display: flex;
  align-items: center;
}
.User__rhscont {
  display: flex;
  flex-direction: column;
  width: 15vw;
  align-items: center;
}

.User__text {
  size: 24px;
  margin-left: 1em;
  font-weight: 500;
}

.User {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.User__cont {
  width: 90vw;
  display: flex;
}

.User__right_side > h1 {
  margin-bottom: 20px;
}

.User__right_side {
  margin-left: 5em;
  flex: 2;
  margin-top: 3.5em;
  margin-bottom: 10vh;
}

.User__box_cont {
  display: flex;
}

.User__box {
  width: 30vw;
  height: 35vh;
  border-radius: 5px;
  margin-right: 7vw;
}

.User__username {
  font-weight: 700;
  size: 24px;
  line-height: 29.05px;
  align-items: center;
  text-align: center;
  margin: 0.6em;
}

.User__bio {
  font-weight: 400;
  size: 14px;
  line-height: 16.94px;
  text-align: center;
  margin: 1em;
}

.User__button {
  background-color: var(--color-secondary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em;
  font-weight: 500;
  cursor: pointer;
  border: none;
  font-size: 1.1em;
}

.User__icon {
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.2em;
}

.User__tabs {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  cursor: pointer;
}

.User hr {
  width: 100%;
  margin-bottom: 2em;
}

.User__boxsuper {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* This creates two columns of equal width */
  gap: 3em; /* This adds a gap between the grid items */
}
