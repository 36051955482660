.CourseDash {
  display: flex;
}

.CourseDash > div {
  margin: 2em;
  padding: 1em;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  min-height: 80vh;
}

.CourseDash__left {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CourseDash__left > img {
  margin: 1em;
  width: 15em;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  height: calc(15em * 0.6);
}

.CourseDash__right {
  flex: 4;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.CourseDash__options {
  display: flex;
  justify-content: space-evenly;
  margin: 1em;
  margin-bottom: 2em;
}

.CourseDash__button {
  padding: 1em;
  border: none;
  border-radius: 5px;
  background-color: var(--color-secondary);
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.CourseDash__box {
  margin: 0.5em 1em 2em 1em;
  padding: 1em;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  min-height: 40vh;
}

.CourseDash__purchases {
  flex: 1.4;
}

.CourseDash__volume {
  flex: 1;
  margin-left: 5em;
}

.CourseDash__overviews {
  display: flex;
  justify-content: space-evenly;
  margin: 1em;
}
