.CourseContent {
  margin-top: 1em;
  margin-left: 1em;
  margin-bottom: 2em;
}

.CourseContent__heading {
  font-size: 2.5rem;
  font-weight: 900;
}

.CourseContent__sections {
  display: flex;
  align-items: center;
}

.CourseContent__sections > h2 {
  font-size: 20px;
  font-weight: 900;
}

.CourseContent__sections > h3 {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

li::marker {
  font-size: 22px;
}

.CourseContent__sections > h4 {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.CourseContent__buttons {
  border: none;
  background: none;
  margin-top: 0.3em;
  cursor: pointer;
  padding: 0;
  margin-left: 0.4em;
}

.CourseContent__span_box {
  display: flex;
}
.CourseContent__span_box > span {
  margin-right: 0.5em;
  text-decoration: underline;
}

.CourseContent__newButton {
  color: white;
  background-color: var(--color-secondary);
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-weight: 600;
  border: none;
  margin-left: 2em;
  cursor: pointer;
}

.CourseContent__newButtonBottom {
  color: white;
  background-color: var(--color-secondary);
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-weight: 600;
  border: none;
  margin: 1em 0 2em 0;
  margin-left: 2em;
  cursor: pointer;
}

.CourseContent__checks {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
.CourseContent__checks > div {
  margin-right: 1em;
}
