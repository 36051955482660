.notification h3 {
  color: rgb(32, 32, 32);
}

.notification {
  display: flex;
  align-items: center;
}

.notification__left {
  margin-right: 1em;
}

.notification__right > button {
  font-weight: bolder;
  font-size: 1.5em;
  background: none;
  border: 1px solid black;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
