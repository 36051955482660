.Form {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
}

.Form__showhide {
  background: none;
  border: none;
  position: relative;
  left: -3vw;
  top: 0.5vh;
}

.Form__input_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 0.8em;
}

.Form__backgroung-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.Form__card {
  width: 31.25rem;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid;
  padding: 1.25rem;
  margin-bottom: 2em;
  margin-top: 2em;
}

.Form__card__nostyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2em;
}

.Form__site_name {
  display: flex;
  width: 31.25rem;
  height: 2rem;
  justify-content: center;
  color: #000000;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}

.Form__description {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  color: #626262;
  padding-bottom: 2px;
  height: 2.5rem;
  margin-top: 2em;
}

.Form__group {
  margin: 2.5px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Form__label {
  margin-left: 2.5em;
  font-size: 0.9rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.Form__recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
  margin-left: 4em;
}

.Form__input {
  color: #333;
  font-size: 0.9rem;
  margin: 0 auto;
  padding: 20px;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 70%;
  display: block;
  border-bottom: 0.1rem solid;
  transition: all 0.3s;
}

.Form__input:placeholder-shown + .Form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(4rem);
  transform: translateY(4rem);
}

.Form__btn {
  border-radius: 10px;
  background: var(--color-primary);
  color: #fff;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.5em 1.5em;
}
