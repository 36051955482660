.CourseCard {
  padding: 0.5em;
  border: 1px solid lightgray;
  border-radius: 1em;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.CourseCard:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.CourseCard__info {
  margin-left: 1em;
  margin-top: 0.5em;
  line-height: 1.5em;
}

.CourseCard__img {
  width: 25vw;
  height: calc(25vw * 0.6);
  border-radius: 1em;
}

.CourseCard h3 {
  font-weight: normal;
}

.CourseCard h4 {
  font-weight: normal;
}

.CourseCard span {
  font-weight: bold;
}

.CourseCard__edit {
  margin-top: 1em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 0.5em;
}

.CourseCard__statusMod {
  margin-bottom: 1em;
  border: 1px solid lightgray;
  background-color: transparent;
  border-radius: 0.5em;
  padding: 0.5em 1em;
}
