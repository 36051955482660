.App__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #0f8b32;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
}

.App__foot {
  text-align: center;
  border-top: 1px solid black;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
}

a {
  text-decoration: none;
  color: black;
}

.App__rightBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App__rightLink {
  text-decoration: underline;
  color: white;
  background-color: #0f8b32;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: 2em;
}

.notifications-dropdown {
  position: absolute;
  top: 100%;
  right: 12vw;
  width: 20vw;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: auto;
  max-height: 300px;
}

.notifications-dropdown::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.notifications-dropdown > div {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notifications-dropdown > div:last-child {
  border-bottom: none;
}

.notificationButton {
  display: flex;
  border: none;
  background: none;
  margin-bottom: 0.2em;
  position: relative;
  cursor: pointer;
  left: 1em;
}
.notificationButton > p {
  border-radius: 50%;
  padding: 0.5px;
  background-color: var(--color-primary);
  width: 1.5em;
  height: 1.5em;
  color: white;
  position: relative;
  top: -0.5em;
  left: -0.7em;
}

.notifications-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border-radius: 5px;
  background-color: var(--color-secondary);
  color: white;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.App__siteName {
  color: #0f8b32 !important;
}

* {
  font-family: Playfair Display, serif;
}

textarea {
  resize: none;
}

.popup-content {
  border-radius: 1em !important;
}

@media screen and (max-width: 930px) {
  .popup-content {
    width: 90% !important;
  }
}

.modal {
  padding: 1em;
}

.md {
  width: 100% !important;
}

.close {
  background-color: white;
  border: none;
  font-size: large;
  font-weight: bold;
}

body::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 167, 22, 0.418);
}
body::-webkit-scrollbar-thumb {
  background-color: #0f8b32;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 20%,
    transparent 20%,
    transparent 40%,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.3) 60%,
    transparent 60%,
    transparent 80%,
    rgba(255, 255, 255, 0.3) 80%
  );
  border-radius: 10px;
}

form {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Document__container {
  padding: 2em;
  line-height: 2.5em;
}
