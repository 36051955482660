.LessonContents {
  display: flex;
  min-height: 82vh;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.LessonContents__box {
  border: 1px solid black;
  margin: 1em;
  padding: 1.5em;
  width: 80%;
  border-radius: 10px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LessonContents__details > p {
  margin: 1em;
  width: 70%;
}

.LessonContents__newDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1em;
}
